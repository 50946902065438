import { Button } from "@/components/ui/button"
import { useTranslations } from "next-intl"
import { Link } from "@/i18n/routing";

interface props {
  error: Error & { digest?: string }
  reset: () => void
}

export function ErrorMessage({error, reset}: props) {
  const t = useTranslations('Errors')

  return (
    <div className="flex flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <div className="mx-auto h-12 w-12 text-primary" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
          {t('somethingWentWrong')}
        </h1>
        <p className="mt-4 text-muted-foreground">
          {t('weAreSorry')}
        </p>
        <pre className="mt-4 text-center text-sm text-red-500 whitespace-pre-wrap">
          {t('errorMessage', { error: error.message })}
        </pre>
        <div className="mt-6 flex flex-col gap-2 items-center sm:flex-row sm:justify-center">
          <Link
            href="/"
            className="inline-flex w-full sm:w-fit items-center justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            prefetch={false}
          >
            {t('goToHomepage')}
          </Link>
          <Button onClick={reset} variant="outline" className="w-full sm:w-auto">
            {t('tryAgain')}
          </Button>
        </div>
      </div>
    </div>
  )
}
